import React, { useEffect } from 'react'
import { BrowserRouter, Routes, Route } from "react-router-dom";
import HomePage from './pages/HomePage'
import "./App.css"
import Privacy from './pages/Privacy';
import AgentHomePage from './pages/AgentHomePage';
import CounsellorHomePage from './pages/CounsellorHomePage';
import AboutUs from './pages/AboutUs';
import Career from './pages/Career';


function App() {



  return (

    <BrowserRouter>
      <Routes>
        <Route path='/' element={<HomePage />} />
        <Route path='/recruiters' element={<AgentHomePage />} />
        <Route path='/councellors' element={<CounsellorHomePage />} />
        <Route path='/about-us' element={<AboutUs />} />
        <Route path='/privacy' element={<Privacy />} />
        <Route path='/career' element={<Career />} />
      </Routes>
    </BrowserRouter>


  )
}

export default App