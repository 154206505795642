import React from "react";
import { Link } from "react-router-dom";
import HomeFooter from "../../components/HomeFooter";

const Career = () => {
    return (
        <div>
            {/* Header */}
            <header className="d-flex align-items-center justify-content-around bg-primary text-white py-4 px-5 shadow-sm" >
                <Link to="/">
                    <svg
                        width={151} height={24}
                        viewBox="0 0 151 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M5.46613 5.61497V10.1711H12.7863V14.4064H5.46613V19.3476H13.7452V23.7433H0V1.21925H13.7452V5.61497H5.46613Z"
                            fill="#5026F9"
                        />
                        <path
                            d="M16.2071 14.7594C16.2071 12.9198 16.548 11.3048 17.23 9.91444C17.9332 8.52406 18.8815 7.45454 20.0749 6.70588C21.2683 5.95722 22.6002 5.58289 24.0706 5.58289C25.2427 5.58289 26.3082 5.82888 27.2672 6.32086C28.2475 6.81283 29.0147 7.47593 29.5687 8.31016V0H35.0349V23.7433H29.5687V21.1765C29.0573 22.0321 28.3221 22.7166 27.3631 23.2299C26.4254 23.7433 25.3279 24 24.0706 24C22.6002 24 21.2683 23.6257 20.0749 22.877C18.8815 22.107 17.9332 21.0267 17.23 19.6364C16.548 18.2246 16.2071 16.5989 16.2071 14.7594ZM29.5687 14.7914C29.5687 13.4225 29.1851 12.3422 28.418 11.5508C27.6721 10.7594 26.7557 10.3636 25.6689 10.3636C24.5821 10.3636 23.6551 10.7594 22.8879 11.5508C22.142 12.3209 21.7691 13.3904 21.7691 14.7594C21.7691 16.1283 22.142 17.2193 22.8879 18.0321C23.6551 18.8235 24.5821 19.2192 25.6689 19.2192C26.7557 19.2192 27.6721 18.8235 28.418 18.0321C29.1851 17.2406 29.5687 16.1604 29.5687 14.7914Z"
                            fill="#5026F9"
                        />
                        <path
                            d="M52.5747 12.1925C53.8747 12.4706 54.9189 13.123 55.7074 14.1497C56.4959 15.1551 56.8901 16.3102 56.8901 17.615C56.8901 19.4973 56.2295 20.9947 54.9082 22.107C53.6083 23.1979 51.7863 23.7433 49.4421 23.7433H38.9893V1.21925H49.0905C51.3707 1.21925 53.1501 1.74331 54.4288 2.79144C55.7287 3.83957 56.3787 5.26203 56.3787 7.05882C56.3787 8.38503 56.027 9.48663 55.3238 10.3636C54.6419 11.2406 53.7255 11.8503 52.5747 12.1925ZM44.4555 10.3316H48.0356C48.9307 10.3316 49.6126 10.139 50.0814 9.75401C50.5716 9.34759 50.8166 8.75936 50.8166 7.9893C50.8166 7.21925 50.5716 6.63102 50.0814 6.2246C49.6126 5.81818 48.9307 5.61497 48.0356 5.61497H44.4555V10.3316ZM48.4831 19.3155C49.3995 19.3155 50.1027 19.1123 50.5929 18.7059C51.1043 18.2781 51.3601 17.6684 51.3601 16.877C51.3601 16.0856 51.0937 15.4652 50.5609 15.016C50.0495 14.5668 49.3356 14.3422 48.4192 14.3422H44.4555V19.3155H48.4831Z"
                            fill="#5026F9"
                        />
                        <path
                            d="M77.6735 5.83957V23.7433H72.2074V21.3048C71.6533 22.0963 70.8968 22.738 69.9378 23.2299C69.0002 23.7005 67.956 23.9358 66.8052 23.9358C65.4413 23.9358 64.2373 23.6364 63.1931 23.0374C62.1489 22.4171 61.3391 21.5294 60.7637 20.3743C60.1883 19.2192 59.9006 17.861 59.9006 16.2995V5.83957H65.3348V15.5615C65.3348 16.7594 65.6438 17.6898 66.2618 18.3529C66.8798 19.016 67.7109 19.3476 68.7551 19.3476C69.8206 19.3476 70.6624 19.016 71.2804 18.3529C71.8984 17.6898 72.2074 16.7594 72.2074 15.5615V5.83957H77.6735Z"
                            fill="#5026F9"
                        />
                        <path
                            d="M80.513 14.7914C80.513 12.9305 80.8859 11.3048 81.6318 9.91444C82.3989 8.52406 83.4538 7.45454 84.7964 6.70588C86.1602 5.95722 87.7159 5.58289 89.4634 5.58289C91.701 5.58289 93.5656 6.17112 95.0574 7.34759C96.5704 8.52406 97.5613 10.1818 98.0302 12.3209H92.2124C91.7223 10.9519 90.7739 10.2674 89.3675 10.2674C88.3659 10.2674 87.5667 10.6631 86.97 11.4545C86.3733 12.2246 86.075 13.3369 86.075 14.7914C86.075 16.246 86.3733 17.369 86.97 18.1604C87.5667 18.9305 88.3659 19.3155 89.3675 19.3155C90.7739 19.3155 91.7223 18.631 92.2124 17.262H98.0302C97.5613 19.3583 96.5704 21.0053 95.0574 22.2032C93.5443 23.4011 91.6796 24 89.4634 24C87.7159 24 86.1602 23.6257 84.7964 22.877C83.4538 22.1283 82.3989 21.0588 81.6318 19.6684C80.8859 18.2781 80.513 16.6524 80.513 14.7914Z"
                            fill="#5026F9"
                        />
                        <path
                            d="M111.854 23.7433L106.42 16.2353V23.7433H100.954V0H106.42V13.123L111.822 5.83957H118.567L111.151 14.8235L118.631 23.7433H111.854Z"
                            fill="#5026F9"
                        />
                        <path
                            d="M137.528 14.5027C137.528 15.016 137.496 15.5508 137.432 16.107H125.061C125.147 17.2192 125.498 18.0749 126.116 18.6738C126.756 19.2513 127.533 19.5401 128.45 19.5401C129.814 19.5401 130.762 18.9626 131.295 17.8075H137.112C136.814 18.984 136.271 20.0428 135.482 20.984C134.715 21.9251 133.745 22.6631 132.573 23.1979C131.401 23.7326 130.091 24 128.642 24C126.894 24 125.338 23.6257 123.975 22.877C122.611 22.1283 121.545 21.0588 120.778 19.6684C120.011 18.2781 119.627 16.6524 119.627 14.7914C119.627 12.9305 120 11.3048 120.746 9.91444C121.513 8.52406 122.579 7.45454 123.943 6.70588C125.306 5.95722 126.873 5.58289 128.642 5.58289C130.368 5.58289 131.902 5.94652 133.245 6.67379C134.587 7.40107 135.631 8.4385 136.377 9.78609C137.144 11.1337 137.528 12.7059 137.528 14.5027ZM131.934 13.0588C131.934 12.1176 131.614 11.369 130.975 10.8128C130.336 10.2567 129.537 9.97861 128.578 9.97861C127.661 9.97861 126.883 10.246 126.244 10.7807C125.626 11.3155 125.242 12.0749 125.093 13.0588H131.934Z"
                            fill="#5026F9"
                        />
                        <path
                            d="M150.353 19.0909V23.7433H147.572C145.59 23.7433 144.045 23.262 142.937 22.2995C141.829 21.3155 141.275 19.7219 141.275 17.5187V10.3957H139.101V5.83957H141.275V1.47594H146.741V5.83957H150.321V10.3957H146.741V17.5829C146.741 18.1176 146.869 18.5027 147.124 18.738C147.38 18.9733 147.806 19.0909 148.403 19.0909H150.353Z"
                            fill="#5026F9"
                        />
                    </svg>
                </Link>
                <h1 className="m-0 text-center flex-grow-1">Join Our Team</h1>
            </header>

            {/* Job Description Section */}
            <main className="container my-5" style={{ fontFamily: "Poppins" }}>
                <div className="card p-5 shadow-lg rounded border-0" style={{ background: "#ffffff", borderLeft: "5px solid #5026F9" }}>
                    <h2 className="text-primary mb-4 text-center">Student Coordinator - International Studies</h2>

                    <h4 className="mt-4 text-secondary">Experience:</h4>
                    <p>1 to 2 years</p>
                    <h4 className="mt-4 text-secondary">Skills:</h4>
                    <ul className="list-unstyled ms-3">
                        <li className="mb-2">🎯 Relationship building</li>
                        <li className="mb-2">📝 Written and oral English communication</li>
                        <li className="mb-2">🤝 Client support</li>
                    </ul>

                    <h4 className="mt-4 text-secondary">Role Overview:</h4>
                    <ul className="list-unstyled ms-3">
                        <li className="mb-2">✅ Excellent written and verbal communication skills</li>
                        <li className="mb-2">✅ Experience in internal and external relationship building and working in teams</li>
                        <li className="mb-2">✅ Problem-solving skills with great attention to detail</li>
                        <li className="mb-2">✅ Ability to write grammatically error-free content and review Statement of Purpose drafted by candidates</li>
                        <li className="mb-2">✅ Familiarity with MS Office and other tools</li>
                        <li className="mb-2">✅ Experience in managing Global Universities in UK, USA, and/or Australia preferred</li>
                    </ul>

                    <h4 className="mt-4 text-secondary">Responsibilities:</h4>
                    <ul className="list-unstyled ms-3">
                        <li className="mb-2">📌 Manage new online or walk-in students and assist them to identify suitable universities and courses</li>
                        <li className="mb-2">📌 Support candidates in the application and admission process</li>
                        <li className="mb-2">📌 Participate in Seminars with foreign Universities</li>
                        <li className="mb-2">📌 Co-ordinate with foreign Universities on submitted applications</li>
                        <li className="mb-2">📌 Create and submit reports</li>
                    </ul>

                    <h4 className="mt-4 text-secondary">Qualifications:</h4>
                    <ul className="list-unstyled ms-3">
                        <li className="mb-2">🎓 Master’s Degree, preferably in English</li>
                    </ul>

                    <h4 className="mt-4 text-secondary">Benefits:</h4>
                    <ul className="list-unstyled ms-3">
                        <li className="mb-2">💰 CTC range: INR 2.4-3L p.a. (negotiable)</li>
                        <li className="mb-2">🏖️ Paid time off and holidays</li>
                        <li className="mb-2">📚 Professional development opportunities</li>
                    </ul>

                    <h4 className="mt-4 text-secondary">How to Apply:</h4>
                    <p>
                        Please submit your resume and cover letter to <b><a href="mailto:sharmistha.m@stylopay.com" className="text-primary">sharmistha.m@stylopay.com</a></b>.
                        In your cover letter, please highlight your relevant experience and why you are interested in joining our team at Edbucket.
                        Edbucket is an equal opportunity employer and welcomes applications from all qualified individuals.
                    </p>
                </div>
            </main>

            {/* Footer */}
            <HomeFooter />
        </div>
    );
};

export default Career;
